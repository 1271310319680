import React from 'react'
import styled from 'styled-components'
import { colors, desktopBp, fonts, mobileBp } from '../../../styles'

import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingLevel from '../../shared/HeadingLevel/HeadingLevel'

const SectionTable = ({
    data
}) => {
    const {
        name,
        title,
        subText,
        subTitle,
        displayTable,
        tableContents,
        headingLevel,
        textAlign
    } = data

    const extraGrid = () => {
        const variable = 3 - tableContents.length % 3
        const array = []
        for (let index = 0; index < variable; index++) {
            array.push(`${index}`)
        }
        return array
    }
    const leftover_array = extraGrid()

    return (
        <Wrapper>
            <Title_Wrapper className='container container-md'>
                <HeadingLevel title={title} headingLevel={headingLevel} />
                <Sub_Title>{subTitle}</Sub_Title>
                <Title_Desc>{subText?.subText}</Title_Desc>
            </Title_Wrapper>
            <Table_Content
                className={`${tableContents.length == 2 ? 'container container-lg' : ''}`}
                numberOfItem={tableContents.length < 3 ? tableContents.length : '3'}
            >
                {
                    tableContents.map((item, index) => (
                        <Item align={textAlign} className={`${displayTable ? 'grid-item' : ''}`} key={index}>
                            {
                                item.image && <Image>
                                    <GatsbyImage image={item.image.gatsbyImageData} alt={item.title || name && `${name} - Image (${index})` || item.image.title} />
                                </Image>
                            }
                            <Item_Title>{item.title}</Item_Title>
                            <Item_Description>{item.description?.description}</Item_Description>
                        </Item>
                    ))
                }
                {
                    leftover_array.map((item, index) => (
                        <Item className={`${displayTable ? 'grid-item' : ''}`} key={index}>&nbsp;</Item>
                    ))
                }
            </Table_Content>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    margin-top: 40px;
    padding-top: 40px;
`

const Title_Wrapper = styled.div`
    text-align: center;
`

const Image = styled.div``

const Sub_Title = styled.p`
    margin-top: 10px;
    font-size: 1.125rem;
    line-height: 21px;
`

const Title_Desc = styled.div`
    margin-top: 40px;
    font-size: 0.875rem;
    line-height: 22px;
`

const Table_Content = styled.div`
    margin-top: 50px;
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.numberOfItem}, 1fr)`};
    ${desktopBp}{
        grid-template-columns: repeat(1, 1fr);
        margin: 40px 10px 0;
    }
    ${mobileBp}{
        border-top: 1px solid #565656;
        border-bottom: 1px solid #565656;
        .grid-item:nth-child(n+3){
            border-top: 1px solid #565656
        }
        .grid-item:not(:nth-child(3n)){
            border-right: 1px solid #565656;
        }
    }
    ${desktopBp}{
        .grid-item:first-child{
            padding-top: 0;
        }
        .grid-item:not(:last-child){
            border-bottom: 1px solid #565656
        }
    }
`

const Item = styled.div`
    padding: 40px 20px 50px;
    ${desktopBp}{
        padding: 20px 5px;
    }
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: ${(props) => props.align || 'left'};
`

const Item_Title = styled.p`
    color: ${colors.gold};
    font-weight: 500;
    font-family: ${fonts.bold};
    font-size: 1rem ;
    line-height: 21px;
    letter-spacing: -3px;
`

const Item_Description = styled.p`
    font-size: 0.875rem;
    font-family: ${fonts.regular};
    line-height: 23px;
    margin-top: 20px;
    letter-spacing: -2px;
`

export default SectionTable