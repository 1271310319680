import React from 'react'

import styled from 'styled-components';

import { GatsbyImage } from 'gatsby-plugin-image';
import { alignment, colors, desktopBp, fonts, mobileBp } from '../../../styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";

import { Scrollbar } from "swiper";
import "swiper/css/scrollbar";

const SectionSliderGrid = ({ data }) => {
    const {
        title,
        titleAlignment,
        numberOfGridItems,
        gridItems,
        columnGap,
        containerSize,
        stlMinHeight
    } = data


    return (
        <div
            className={`container container-${containerSize || 'lg'} `}
            style={{ overflow: 'hidden' }}
        >
            <Wrapper>
                <Title alignment={titleAlignment}>{title}</Title>
                <Swiper_Container>
                    <Swiper
                        spaceBetween={30}
                        height={100}
                        scrollbar={{
                            hide: true,
                        }}
                        modules={[Scrollbar]}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.14
                            },
                            768: {
                                slidesPerView: 3
                            },
                        }}
                    >
                        {
                            gridItems.map((item: any, index: number) => {
                                const { __typename } = item
                                if (__typename === "ContentfulStlComponentCard")
                                    return (
                                        <SwiperSlide key={index}>
                                            <Card
                                                color={item.backgroundColor || '#272A31'}
                                                minHeight={stlMinHeight}
                                            >
                                                <div className="shadow">&nbsp;</div>
                                                <Img
                                                    width={item.imageWidth}
                                                >
                                                    <GatsbyImage
                                                        image={item.image.gatsbyImageData}
                                                        alt={`${item.image.title}`}
                                                        loading='lazy'
                                                        className="card-image"
                                                    />
                                                </Img>
                                                {
                                                    item.title &&
                                                    <Card_Title> {item.title} </Card_Title>
                                                }
                                                <Card_Desc>{item.description?.description}</Card_Desc>
                                            </Card>
                                        </SwiperSlide>
                                    )
                            })
                        }
                    </Swiper>
                </Swiper_Container>
            </Wrapper>
        </div>
    )
}

const Wrapper = styled.div`
    margin-top: 80px;
`

const Title = styled.h2`
    color: ${colors.gold};
    font-size: 1.75rem;
    margin-bottom: 40px;
    text-align: ${(props) => alignment[props.alignment]};
    font-family: ${fonts.bold};
    font-weight: bold;
`

const Swiper_Container = styled.div`
    display: block;
    .swiper{
        overflow: unset;
        .swiper-slide {
            height: auto;
        }
    }
    .swiper-scrollbar{
        opacity: 1 !important;
        border-bottom: 1px solid #fff;
        bottom: -40px !important;
        .swiper-scrollbar-drag{
            background: #D3A129;
            border-radius: 0;
        }
    }
    padding-bottom: 45px;
`

const Card = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* height: fit-content; */
    cursor: pointer;
    /* min-height: 315px; */
    justify-content: flex-start;
    /* min-height: ${(props) => props.minHeight ? `${props.minHeight}px` : 'auto'}; */
    .shadow{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 70%;
        background: transparent linear-gradient(180deg, transparent 0%, #272A31 100%) 0% 0% no-repeat padding-box;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        z-index: 0;
        opacity: 0.7;
    }

    &:hover{
        .shadow{
            background: ${(props) => `transparent linear-gradient(180deg, transparent 0%, ${props.color} 100%) 0% 0% no-repeat padding-box`};
        }
    }
`

const Img = styled.div`
    width: ${(props) => props.width || '224px'};
    height: ${(props) => props.width || '224px'};
`

const Empty = styled.div`
    flex-grow: 1;
`

const Card_Desc = styled.p`
    letter-spacing: 0;
    padding: 0 30px;
    line-height: 20px;
    font-family: ${fonts.regular};
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 45px;
`

const Card_Title = styled.span`
    letter-spacing: 0;
    padding: 0 30px;
    font-size: 1.375rem;
    color: ${colors.gold};
    text-align: center;
    margin-top: 43px;
    font-family: ${fonts.bold};
    z-index: 1;
    font-weight: 500;

`

export default SectionSliderGrid