import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react'
import styled from 'styled-components';
import { desktopBp, mobileBp } from '../../../styles';

const SectionMenu = ({
    data
}) => {
    const [option, setOption] = useState(0)
    const {
        indexTitle1,
        indexTitle2,
        indexTitle3,
        indexTitle4,
        indexTitle5,
        indexDescription1,
        indexDescription2,
        indexDescription3,
        indexDescription4,
        indexDescription5,
        indexImage1,
        indexImage2,
        indexImage3,
        indexImage4,
        indexImage5,
    } = data
    const titles = [indexTitle1, indexTitle2, indexTitle3, indexTitle4, indexTitle5]
    const descriptions = [indexDescription1, indexDescription2, indexDescription3, indexDescription4, indexDescription5]
    const images = [indexImage1, indexImage2, indexImage3, indexImage4, indexImage5]

    return (
        <Container className='container-fs'>
            <Wrapper>
                <Tile_Container>
                    {
                        titles.map((tile, index) => (
                            <Tile
                                className='tile_name'
                                key={index}
                                active={index === option}
                                onClick={() => setOption(index)}
                            >{tile}</Tile>
                        ))
                    }
                </Tile_Container>
                <Tile_Dropdown onChange={(e) => setOption(e.target.value)}>
                    {
                        titles.map((tile, index) => (
                            <Tile_Option key={index} value={index}>{tile}</Tile_Option>
                        ))
                    }
                </Tile_Dropdown>
                <Description_Container>{descriptions[option].description}</Description_Container>
                <Image_Container
                    image={images[option].gatsbyImageData}
                    alt={titles[option]}
                    objectFit="fill"
                />
            </Wrapper>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 50px;
    margin-bottom: 40px;
    padding-bottom: 40px;
`

const Wrapper = styled.div`
    display: flex;
    min-height: 420px;
    ${desktopBp}{
        flex-direction: column;
        padding: 0 16px;
    }
`

const Tile_Dropdown = styled.select`
    ${mobileBp}{
        display: none;
    }
    background-color: transparent;
    padding: 16px 15px;
    color: #FFFFFF;
    margin-bottom: 40px;
    border: 1px solid #8C8C8C;
`

const Tile_Option = styled.option`
    color: #000;
    font-weight: 500;
`

const Tile_Container = styled.div`
    width: 25%;
    display: grid;
    align-items: center;
    .tile_name:first-child{
        border-top: 1px solid #333333;
    }
    .tile_name:not(:last-child) {
        border-bottom: 1px solid #333333;
    }
    ${desktopBp}{
        display: none;
    }
`

const Tile = styled.p`
    padding-left: 60px;
    height: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: ${(props) => props.active ? '#000000' : '#5F5F5F'};
    background-color: ${(props) => props.active ? '#FFFFFF' : '#2A2A2A'};
    cursor: pointer;
`

const Description_Container = styled.p`
    width: 25%;
    font-size: 14px;
    display: flex;
    font-weight: 500;
    padding: 0 30px;
    line-height: 22px;
    align-items: center;
    background-color: #FFFFFF;
    color: #000000;
    ${desktopBp}{
        width: 100%;
        order: 2;
        padding: 0;
        margin-top: 30px;
        background-color: transparent;
        color: #FFFFFF;
    }
`

const Image_Container = styled(GatsbyImage)`
    width: 50%;
    ${desktopBp}{
        width: 100%;
        order: 1;
    }
`

// const Content_Container = styled.div`
//     width: 50%;
//     display: flex;
//     ${desktopBp}{
//         width: 100%;
//         flex-direction: column;
//     }
// `

export default SectionMenu