import React, { useState } from 'react'
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import { GatsbyImage } from 'gatsby-plugin-image';
import SectionAlternateGrid from '../SectionAlternateGrid/SectionAlternateGrid';
import SectionTitleContainer from '../SectionTitleContainer/SectionTitleContainer';
import SectionCardGrid from '../SectionGrid/SectionCardGrid';
import SectionSliderGrid from '../SectionGrid/SectionSliderGrid';
import SectionGrid from '../SectionGrid/SectionGrid';
import { desktopBp } from '../../../styles';

const SectionSlider = ({
    data
}) => {
    const [option, setOption] = useState(0)
    const {
        tiles,
        sections
    } = data
    return (
        <Container>
            <Tile_Slider>
                <Swiper
                    breakpoints={{
                        320: {
                            slidesPerView: 2.3,
                            spaceBetween: 30
                        },
                        768: {
                            slidesPerView: 6.65,
                            spaceBetween: 85
                        },
                        1400: {
                            slidesPerView: 9.65,
                            spaceBetween: 85
                        }
                    }}
                >
                    {
                        tiles.map((item, index) => (
                            <SwiperSlide
                                key={index}
                                onClick={() => setOption(index)}
                            >
                                <Tiles>
                                    <GatsbyImage
                                        image={item.image.gatsbyImageData}
                                        alt={item.title || item.description?.description}
                                        objectFit="contain"
                                        className={`${option === index ? 'active' : 'non-active'}`}
                                    />
                                    <Tile_desc>{item.description?.description}</Tile_desc>
                                </Tiles>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </Tile_Slider>
            <Content_Display>
                {
                    sections[option]?.sections?.map((section, index) => {
                        switch (section.__typename) {
                            case "ContentfulSectionAlternateGrid":
                                return (
                                    <SectionAlternateGrid
                                        data={section}
                                        key={index}
                                        table_Right_alignment={true}
                                        image_alignment_top={true}
                                    />
                                )
                            case "ContentfulStlSectionTitleContainer":
                                return (
                                    <SectionTitleContainer data={section} key={index} />
                                )
                            case "ContentfulSectionGrid":
                                switch (section.type) {
                                    case "cards":
                                        return (
                                            <SectionCardGrid data={section} key={index} />
                                        )
                                    case "slider":
                                        return (
                                            <SectionSliderGrid data={section} key={index} />
                                        )
                                    default:
                                        return (
                                            <SectionGrid data={section} key={index} />
                                        )
                                }

                        }
                    })
                }
            </Content_Display>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 40px;
`

const Tile_Slider = styled.div`
    padding: 0 20px;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #000D0D;
    ${desktopBp}{
        top: 55px;
    }
`

const Tiles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .non-active {
        opacity: 0.65;
    }
    .active {
        opacity: 1;
    }
    cursor: pointer;
`

const Tile_desc = styled.p`
    color: #D3A129;
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    text-decoration: underline;
    line-height: 21px;
`

const Content_Display = styled.div`
    margin-top: 80px;

    table {
        width: 100%;
        tr{
            td{
                padding: 10px 0;
                p{
                    margin-bottom: unset !important;
                }
            }
            td:first-child{
                margin-right: 50px;
            }
        }
        tr:not(:last-child){
            border-bottom: 1px solid #2D2D2D;
        }
    }
`
export default SectionSlider