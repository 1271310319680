import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo';
import HeroComponent from '../components/sections/HeroComponent/HeroComponent';
import SectionWithVines from '../components/sections/SectionWithVines/SectionWithVines';
import SectionGrid from '../components/sections/SectionGrid/SectionGrid';
import SectionContent from '../components/sections/SectionContent/SectionContent';
import SectionDecorative from '../components/sections/SectionDecorative/SectionDecorative';
import SectionAlternateGrid from '../components/sections/SectionAlternateGrid/SectionAlternateGrid';
import StoryContainer from '../components/Story/StoryContainer/StoryContainer';
import SectionBreadcrumb from '../components/sections/SectionBreadcrumb/SectionBreadcrumb';
import SectionCardGrid from '../components/sections/SectionGrid/SectionCardGrid';
import SectionSliderGrid from '../components/sections/SectionGrid/SectionSliderGrid';
import SectionMenu from '../components/sections/SectionMenu/SectionMenu';
import SectionSlider from '../components/sections/SectionSlider/SectionSlider';
import SectionTable from '../components/sections/SectionTable/SectionTable';
import SectionTitleContainer from '../components/sections/SectionTitleContainer/SectionTitleContainer';

const Template = ({
    pageContext,
    location,
    data: {
        contentfulStlPageTemplate: {
            slug,
            seoTitle,
            seoDescription,
            sections,
            pageTitle
        }
    }
}) => {
    return (
        <div>
            <Seo
                title={seoTitle}
                description={seoDescription?.seoDescription}
                slug={slug}
            />
            <div className='content'>
                {
                    sections.map((section: any, index: number) => {

                        switch (section.__typename) {
                            case "ContentfulComponentBackgroundHero":
                                return (
                                    <HeroComponent data={section} key={index} />
                                )
                            case "ContentfulSectionWithVines":
                                return (
                                    <SectionWithVines data={section} key={index} />
                                )
                            case "ContentfulSectionGrid":
                                switch (section.type) {
                                    case "cards":
                                        return (
                                            <SectionCardGrid data={section} key={index} />
                                        )
                                    case "slider":
                                        return (
                                            <SectionSliderGrid data={section} key={index} />
                                        )
                                    default:
                                        return (
                                            <SectionGrid data={section} key={index} />
                                        )
                                }
                            case "ContentfulSectionContent":
                                return (
                                    <SectionContent data={section} key={index} />
                                )
                            case "ContentfulStlSectionDecorativeItems":
                                return (
                                    <SectionDecorative data={section} key={index} />
                                )
                            case "ContentfulSectionAlternateGrid":
                                return (
                                    <SectionAlternateGrid data={section} key={index} />
                                )
                            case "ContentfulSectionBreadCrumbs":
                                return (
                                    <SectionBreadcrumb data={section} key={index} title={pageTitle} />
                                )
                            case "ContentfulStlComponentMenu":
                                return (
                                    <SectionMenu data={section} key={index} />
                                )
                            case "ContentfulStlSectionIndexing":
                                return (
                                    <SectionSlider data={section} key={index} />
                                )
                            case "ContentfulStlSectionTable":
                                return (
                                    <SectionTable data={section} key={index} />
                                )
                            case "ContentfulStlSectionTitleContainer":
                                return (
                                    <SectionTitleContainer data={section} key={index} />
                                )
                        }
                    })
                }
            </div>
        </div>
    )
}

export const TemplateQuery = graphql`
    query templateQuery ($id: String!){
        contentfulStlPageTemplate(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            pageTitle
            sections {
                __typename
                ...componentBackgroundHero
                ...sectionWithVines
                ...sectionGrid
                ...sectionDecorativeItems
                ...sectionContent
                ...sectionAlternateGrid
                ...sectionBreadcrumb
                ...sectionMenu
                ...sectionTitleContainer
                ... on ContentfulStlSectionIndexing {
                    name
                    tiles {
                        title
                        description {
                            description
                        }
                        image {
                            gatsbyImageData(quality: 75, formats: WEBP)
                        }
                    }
                    sections {
                        __typename
                        ... on ContentfulStlAdditionalSections {
                            sections {
                                __typename
                                ...sectionAlternateGrid
                                ...sectionTitleContainer
                                ...sectionGrid
                            }    
                        }
                    }
                }
                ... on ContentfulStlSectionTable {
                    name
                    title
                    headingLevel
                    subTitle
                    subText {
                        subText
                    }
                    displayTable
                    textAlign
                    tableContents {
                        title
                        description {
                            description
                        }
                        image {
                            gatsbyImageData(quality: 75, formats: WEBP)
                        }
                    }
                }
            }
        }
    }

`

export default Template