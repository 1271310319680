import React from 'react'
import styled from 'styled-components';
import { desktopBp } from '../../../styles';

import { GatsbyImage } from 'gatsby-plugin-image';

import { RichText } from '../RichText/RichText';

const SectionCardGrid = ({
    data
}) => {
    const {
        columnGap,
        numberOfGridItems,
        containerSize,
        type,
        gridItems
    } = data

    return (
        <div className={`container container-${containerSize} `}>
            <Wrapper
                numberOfGrid={numberOfGridItems}
                columnGap={columnGap}
            >
                {
                    gridItems.map((item, index) => {
                        switch (item.__typename) {
                            case "ContentfulComponentInfoCards":
                                return (
                                    <div key={index}>
                                        <GatsbyImage image={item.image?.gatsbyImageData} alt={item.image?.title} />
                                        <Card_Title className='heading heading-3'>{item.cardTitle}</Card_Title>
                                        <Card_Content>
                                            <RichText content={item.cardDescription} />
                                        </Card_Content>
                                    </div>
                                )
                        }
                    })
                }
            </Wrapper>
        </div>
    )
}

const Wrapper = styled.div`
    margin-top: 60px;
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.numberOfGrid || '3'}, 1fr)`};
    column-gap: ${(props) => `${props.columnGap || '0'}px`};
    ${desktopBp}{
        grid-template-columns: repeat(1, 1fr);
    }
`

const Card_Title = styled.h3`
    margin-top: 30px;
`

const Card_Content = styled.div`
    margin-top: 30px;
`

export default SectionCardGrid